<template>
	<div class="main">
		<div class="productplatform-banner">
			<img :src="img_list[0].img" alt="" style="width: 100%;">
		</div>
        <div class="search-box row-center">
            <searchInput :ccc="'20'" @search="searchFun"></searchInput>
        </div>
		<div class="content">
			<div style="background-color: #fff; border-radius: 0.5rem;padding: 1rem;">
				
				<div class="header-list row-center">
					<div :class="(activeNum == item.id ? 'item-select ' : ' ')+'item column-center'" style="width: 12.5%;" @click="active(item.id)" v-for="(item,index) in productCate" :key="index" v-if="index<8">
						<div class="header-image row-center "><img :src="item.icon_img" alt="" /></div>
						<div class="text">{{item.name}}</div>
					</div>
				</div>
				<div class="header-list row-center" style="justify-content: start;margin: 0;">
					<div :class="(activeNum == item.id ? 'item-select ' : ' ')+'item column-center'" style="width: 12.5%;" @click="active(item.id)" v-for="(item,index) in productCate.slice(8, productCate.length)" :key="'item2'+index">
						<div class="header-image row-center "><img :src="item.icon_img" alt="" /></div>
						<div class="text">{{item.name}}</div>
					</div>
				</div>
			</div>
			<div class="list" v-if="productList.data.length > 0">
				<div class="type" @click="path('/productplatform/see/'+ item.id)" v-for="(item,index) in productList.data" :key="index">
					<!-- <img class="type-image" :src="item.img" alt="" /> -->
					<div class="type-image" :style="{backgroundImage: `url(${item.img})`}" v-if="item.img"></div>
					<div class="type-image-nodata" :style="{ 'background': 'url('+require('@/assets/no_img.png')+')'}" v-else></div>
					<div class="text bold row-center"><p class=" line-one">{{ item.title }}</p></div>
				</div>
			</div>
			<div v-if="productList.data.length == 0" class="column-center" style="width: 100%;margin: 3rem 0;">
				<img class="type-image" src="@/assets/nodata.gif" alt=""/>
				<div style="margin-top: 2rem;">{{lang.project_no_data}}</div>
			</div>
			<div class="pagination" v-if="productList.data.length > 0">
				<el-pagination
				background
				:pager-count="5"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page.sync="productList.current_page"
				:page-size="productList.per_page"
				:layout="layout"
				:total="productList.total">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
import searchInput from "@/components/searchInput/index.vue"
import {bannerType} from "@/utils/type.js"
import { 
	getProductCate,
	getProductList, 
	getAdList 
} from '@/api/store.js'
import {
		mapState
	} from 'vuex'
	export default {
		name: "productplatform",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			}),
			layout() {
			  return this.isMobile ? 'prev, pager, next' : 'prev, pager, next, jumper'; // 根据isMobile的值动态设置layout属性值。如果isMobile为true，则不显示跳转输入框。
			}
		},
		data() {
			return {
				type: this.$route.params.type,
				activeNum: 0,
				bannerId: bannerType.productDisplay,
				productCate: [{
					id:0,
					name:'全部',
					icon_img:require("../../assets/productplatform/header-1.png"),
				}],
				productList:[],
				page:1,
				keywords:'',
				img_list:[{img:''}],
				isMobile: false	  
			}
		},
		components:{
			searchInput,
		},
		created() {
			this.getAdListFun()
			this.getProductCateFun()
			this.activeNum = this.$route.params.type
			this.getProductListFun()
			this.productCate[0].name=this.lang.product_show_all
		},
		mounted() {
			window.screenWidth = document.body.clientWidth;
			if(window.screenWidth<=768){
				this.isMobile=true
			}else{
				this.isMobile=false
			}
		},
        beforeRouteUpdate(to, from, next) {
            this.activeNum = to.params.type
			this.keywords = ''
			this.page = 1
			this.getProductListFun()
            next();
        },
		methods:{
			getAdListFun(){
				// 获取广告位图片
				let data = {
					cate_id:this.bannerId
				}
				getAdList(data).then(res=>{
					if(res.data.img_list){
						this.img_list = res.data.img_list
					}
				})
			},
			getProductCateFun(){
				// 获取分类
				getProductCate().then(res=>{
					this.productCate = [...this.productCate, ...res.data.cate_list]
				})
			},
			getProductListFun(){
				// 获取列表
				let data = {
					id:this.activeNum,
					page:this.page,
					keywords:this.keywords
				}
				getProductList(data).then(res=>{
					this.productList = res.data.product_list
				})
			},
			active(num){
				this.activeNum = num
				this.page = 1
				this.getProductListFun()
				// 更新列表数据
			},
			searchFun(e){
				 console.log(e,'e')
				 this.keywords = e
				 this.page = 1
				 this.getProductListFun()
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			handleCurrentChange(e){
				this.page = e
				this.getProductListFun()
			},
			path(routePath){
				 this.$router.push(routePath); 
			}
		}




	}
</script>

<style lang="less">
.main{
	background: #f2f2f2;
    width: 100%;
	padding-bottom: 2rem;
}
	.productplatform-banner{
		.title{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			h1{
				margin-top: 16%;
				font-weight: bold;
				font-size: 3.75rem;
				color: #FFFFFF;
				margin-bottom: 1rem;
			}
			p{
				font-weight: 500;
				font-size: 1.5rem;
				color: #FFFFFF;
				margin-bottom: 0.5rem;
			}
		}
	}
	.productplatform-tab{
		width: 100%;
		height: 7.5rem;
		margin-top: -5rem;
		background: #FFFFFF;
		box-shadow: 0px 0px 21px 0px #4F4F4F;
		border-radius: 8px 8px 8px 8px;
		position: relative;
		z-index: 1
	}
    .search-box{
        width: 100%;
    }
	.content{
		width: 75rem;
		margin: 0 auto;
		.header-list{
			width: 100%;
			margin-bottom: 1rem;
			justify-content: space-between;
			.item{
				cursor:pointer;
				padding: 0.3rem;
				.header-image{
					border-radius: 5rem;
					width:4.5rem;height:4.5rem;
					img{
						width:3rem;height:3rem;
					}
				}
				.header-image:hover{
					box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0, 0.1);
				}
				.text{
					margin-top: 0.3rem;
					font-size: 1rem;
					text-align: center;
					color: #333333;
				}
			}
			.item-select{
				
				.header-image{
					background-color: #fff;
					box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0, 0.1);
				}
				.text{
					color: #D70C19 !important;
				}
			}
		}
		.list{
			width: 100%;
			margin-top: 2rem;
			display: flex;
			flex-wrap: wrap;
			.type{
				width: 17.25rem;
				height: 21.5rem;
				margin-right: 2rem;
				margin-bottom: 2.06rem;
				border-radius: 0.5rem;
				cursor: pointer;
				background-color: #fff;
				.type-image{
					width: 100%;
					height: 17.25rem;
					background-size: 100% auto;
					background-position: center;
					background-repeat: no-repeat;
				}
				.type-image-nodata{
					width: 24rem;
					height: 17.19rem;
					background-size: 50% auto !important;
					background-position: center !important;
					background-repeat: no-repeat !important;
				}
				.text{
					border-radius: 0.5rem;
					width: 100%;
					height: 4.25rem;
					padding: 0 1rem;
					p{
						text-align: center;
						width: 100%;
					}
				}
			}
			.type:nth-child(4n){margin-right:0}
			.type:hover{
					box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,0.4);
			}
		}
		.pagination{
			width: 100%;
			flex-direction:row-reverse
		}
	}
    .header-icon{
        width:1rem;height:1rem
    }
	/* 响应式布局 */
	@media (max-width: 1220px) {
		.content {
		    width: 100%;
			padding: 0 1rem;
		}
		.content .list .type {
		    width: 23%;
		    margin-right: 2.66%;
		}
	}
	@media (max-width: 1024px) {
		.content .list .type{
			height: auto;
		}
		.content .list .type .type-image {
		    height: 14.25rem;
		}
		.content .list .type .type-image-nodata {
		    height: 14.25rem;
		}
	}
	@media (max-width: 853px) {
		.content .list .type{
			height: auto;
		}
		.content .list .type .type-image {
		    height: 12.25rem;
		}    
		.content .list .type .type-image-nodata {
		    height: 12.25rem;
		}    

	}
	@media (max-width: 768px) {
		.searchInput .search[data-v-0b76e525] {
		    width: 76%;
		    top: -3rem;
		}
		.content .list .type {
		    width: 48.5%;
		    margin-right: 3%;
		}    
		.content .list .type:nth-child(2n){
			margin-right: 0;
		}
		.content .list .type .type-image {
		        height: 15.25rem;
		}
		.content .list .type .type-image-nodata {
		        height: 15.25rem;
		}
		.content .header-list {       
			flex-wrap: wrap;
		}
		
		.content .header-list .item{ 
			width: 25% !important;
		}
		.content .header-list .item .header-image img {
		    margin-top: 0.5rem;
		}
	}
	@media (max-width: 430px) {
		    .content .list .type .type-image {
		        height: 11.25rem;
		    }
		    .content .list .type .type-image-nodata {
		        height: 11.25rem;
		    }
	}
	
</style>