<template>
    <div class="searchInput">
        
        <div class="search"> 
            <el-input  placeholder=""
                v-model="text">
                <i slot="prefix">
                    <img 
                        class="search-icon"
                        src="@/assets/productplatform/search.png"
                        alt="" />
                </i>
                <i slot="suffix" style="display: flex;align-items: center;">
                    <el-button @click="search()">{{lang.huifa_welfare_search}}</el-button>
                </i>
            </el-input>
        </div>
    </div>
</template>

<script>
import { search } from 'core-js/fn/symbol';
    import {
		mapState
	} from 'vuex'
	export default {
		name: "searchInput",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				text: '',
			}
		},
		props: {
			ccc: {
				type: String,
				default: 'other'
			},
		},
		methods:{
			search(){
				//  console.log(this.ccc,'ccc')
                this.$emit('search',this.text)
			},
			
		}


	}
</script>

<style lang="less" scoped>

/* 响应式布局 */
@media (max-width: 768px) {
    .searchInput .search{
        width: auto !important;
        position: inherit !important;
        margin: 1rem !important;
    }
}
    .search::v-deep .el-input__prefix{
        display: flex;align-items: center;justify-content: center;
        width: 2rem;
    }
    .search::v-deep .el-input.is-active .el-input__inner, .el-input__inner:focus{
        border: none !important;
    }
    .search::v-deep .el-input.is-active .el-input__inner, .el-input__inner:hover{
        border: none !important;
    }
    .search::v-deep .el-input--prefix .el-input__inner{
        padding-left: 40px;
        font-size: 0.88rem;
    }
    .search::v-deep .el-input .el-input__inner{
        height: 2.5rem;
        border-radius: 5rem !important;
        background: #fff;
        opacity: 0.65;
    }
    .search::v-deep .el-button{
        height: 2.49rem;
        position: relative;
        left: 5%;
        top: 0;
        border: none;
        border-radius: 5rem !important;
        background: #D70C19;
        color: #fff;
        font-size: 1.17rem;
    }
    .searchInput{
        width: 75rem;
        .search{
            width: 38rem;
            height: 2.5rem;
            position: relative;
            margin: 0 auto;
            top: -5rem;
        }

    }
</style>